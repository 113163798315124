import React, { useState, useEffect, useRef } from 'react';
import './css/edit.less'

import Bar from '../components/Bar/index'
import Bar_b from '../components/Bar/index_B'
import Bar_c from '../components/Bar/index_C'
import bus from '../components/bus'
import Modal from '../components/Modal'
import AvatarsModal from '../components/AvatarsModal'
import { Carousel, Button, message, Popconfirm, Radio, Col, Row,Spin } from "antd";
import { ShareAltOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Score, fetchname, fetchstufflist, fetchaccountdata, setdefaultavatar } from '../request/api'
import store from '../store'

const Edit = () => {
  const optionsWithDisabled = [
    {
      label: 'RANDOM',
      value: 'RANDOM',
    },
    {
      label: 'PREMIUM',
      value: 'PREMIUM',
    },

  ];
  const [Cursorvalue, setCursorValue] = useState('RANDOM');
  
const [loading, setLoading] = useState(false);
  const CursoronChange = ({ target: { value } }) => {
    console.log('radio1 checked', value);
    setCursorValue(value);
  };
  const [utoken, setUtoken] = useState("a32bb94563137eea98f1fd09e1ddcb6b6686ae39b7e10cc541231654b1bf4")
  const [gid, setGid] = useState("CLCS")
  const [changeAvatarid, setChangeAvatarid] = useState(-1)
  useEffect(() => {

    // let gamer_reg = JSON.parse(localStorage.getItem("gamer_reg"))
    // // total_credits

    // setcredits(gamer_reg.total_credits)

    // 监听state的变化
    let unsubscribe = store.subscribe(() => {

      setChangeAvatarid(store.getState().changeAvatarid)
    })
    return () => {
      // 取消监听
      unsubscribe();
    }
  }, [])
  useEffect(() => {

 

    // 监听state的变化
    let unsubscribe = store.subscribe(() => {

      // setChangeAvatarid(store.getState().cheernum)
    })
    return () => {
      // 取消监听
      unsubscribe();
    }
  }, [])
  const confirm = (e) => {
    let gamer_reg = JSON.parse(localStorage.getItem("gamer_reg"))

    let UUID = gamer_reg.uuid
    console.log(e);
    setdefaultavatar({
      uuid: UUID,
      stuff_id: e.propertyavatar.id,
    }).then((res) => {
      console.log(res);
      // res.data.current_avatar_url
      const action3 = {
        type: "changeAvatar",       // type属性是必须要写的，用于校验
        value: res.data.current_avatar_url,          // value代表要修改为什么值
      }
      // 将action用dispatch方法传递给store
      store.dispatch(action3);
      const action4 = {
        type: "changeAvatarid",       // type属性是必须要写的，用于校验
        value: res.data.current_avatar_id,          // value代表要修改为什么值
      }

      store.dispatch(action4);
      gamer_reg.avatar_url = res.data.current_avatar_url + ""
      gamer_reg.avatar_id = res.data.current_avatar_id + ""
      localStorage.setItem("gamer_reg", JSON.stringify(gamer_reg));
    })
    // message.success('Click on Yes');
  };
  const cancel = (e) => {
    console.log(e);
    // message.error('Click on No');
  };
  const contentStyle = {
    height: "400px",
    lineHeight: "400px",
    textAlign: "center",
    background: "#364d79",
  };
  const carouselEL = useRef(null);
  const [show, setShow] = useState(null)
  const [resscore, setResscore] = useState({ game_name: "Wall smash game" })
  let [time, setTime] = useState([1])
  let [scores, setScores] = useState([1])
  let [comscore, setComscore] = useState([])
  let [comscoreb, setComscoreb] = useState([])
  let [topscore, setTopscore] = useState([])
  let [playtotal, setPlaytotal] = useState(0)
  let [dialog, setDialog] = useState(false)
  let [avatarsdialog, setAvatarsdialog] = useState(false)
  let [avatarsdialogdata, setAvatarsdialogdata] = useState({})
  let [topplayer, setTopplayer] = useState([])
  let [alist, setAlist] = useState([])
  let [mylist, setMylist] = useState([])
  let [xx, setXx] = useState([])
  let [yy, setYy] = useState([])
  let [mytotal, setMytotal] = useState(12)
  let [mypage, setMypage] = useState(1)
  let [changename, setchangename] = useState("")
  //  游客用户名
  let [tourist, setTourist] = useState("")
  useEffect(() => {
    window.gid="CLCS"
    // const changeGid = {
    //   type: "changeGid",       // type属性是必须要写的，用于校验
    //   value: "",          // value代表要修改为什么值
    // }
      
    //   store.dispatch(changeGid);
    const action4 = {
      type: "changeGid",       // type属性是必须要写的，用于校验
      value: "CLCS",          // value代表要修改为什么值
    }
      
      store.dispatch(action4);


    if (localStorage.hasOwnProperty("utoken") == false) {
      setShow(false)
      return
    }
    let UT = JSON.parse(localStorage.getItem("utoken"))


    // 进入子组件 首先判断缓存中有没有游戏数据
    if (localStorage.hasOwnProperty("gamer_reg") == false) {
      // 缓存中没有游戏数据 调用接口获取
      console.log("首次进入 缓存中没有游戏数据 调用接口获取");
      console.log("页面中的");
      fetchname({
        utoken: UT,

      }).then(
        (res) => {
          console.log(res);
          localStorage.setItem("gamer_reg", JSON.stringify(res.data));
          console.log(store.getState().changeCredits,"clcs监听到了changeCredits的变化");
          let gamer_reg = JSON.parse(localStorage.getItem("gamer_reg"))
          
          let UUID = gamer_reg.uuid
          fetchaccountdata({
            page: 1,
            uuid: UUID,
            type: "cheerleader",
            page_size:12
          }).then((res) => {
            console.log(res);
            setMypage(1)
            if (res.code == "2") {
              let obj = []
              setMylist(obj)
            } else {
              let obj = res.data
              setMylist([...obj])
              setMytotal(res.total_num)
              console.log(mylist);
            }
          
          })
          if (res.data.init_state == 1) {
            let gamer_reg = JSON.parse(localStorage.getItem("gamer_reg"))
            setTourist(gamer_reg.player_name)
            setDialog(true)
          }
          scoresShow()

        })
    } else {
      let gamer_reg = JSON.parse(localStorage.getItem("gamer_reg"))
      if (gamer_reg.init_state == 1) {
        setTourist(gamer_reg.player_name)
        setDialog(true)
      }
      scoresShow()
      console.log("缓存中有游戏数据");
      console.log("页面中的");
    }








  }, [])
  useEffect(() => {


    // 监听state的变化
    let unsubscribe = store.subscribe(() => {
      setchangename(store.getState().cheernum)
      console.log(changename, "变更后已购买图片数量", store.getState().cheernum);
      let gamer_reg = JSON.parse(localStorage.getItem("gamer_reg"))
      if(gamer_reg){
        let UUID = gamer_reg.uuid
        fetchaccountdata({
          page: 1,
          uuid: UUID,
          type: "cheerleader",
          page_size:12
        }).then((res) => {
          console.log(res);
          if (res.code == "2") {
            let obj = []
            setMylist(obj)
          } else {
            setMylist(res.data)
            setMytotal(res.total_num)
          }
          console.log("这是我的图片列表", mylist);
    
        })
      }

    })
    return () => {
      // 取消监听
      unsubscribe();
    }
  }, [])
  function scoresShow() {
    
    let gamer_reg = JSON.parse(localStorage.getItem("gamer_reg"))
    let UT = JSON.parse(localStorage.getItem("utoken"))
    let UUID = gamer_reg.uuid

    fetchstufflist({
      uuid: UUID,
      type: "avatar",
      page_size:18
    }).then((res) => {
      console.log(res);
      setAlist(res.data)
    })
    fetchaccountdata({
      page: 1,
      uuid: UUID,
      type: "cheerleader",
      page_size:12
    }).then((res) => {
      console.log(res);
      if (res.code == "2") {
        let obj = []
        setMylist(obj)
      } else {
        setMylist(res.data)
        setMytotal(res.total_num)
      }
      console.log("这是我的图片列表", mylist);

    })
    if (gamer_reg.gamer_reg[gid] == "reg") {

      setShow(true)



      const action2 = {
        type: "changeCredits",       // type属性是必须要写的，用于校验
        value: gamer_reg.total_credits,          // value代表要修改为什么值
      }
      const action3 = {
        type: "changeAvatar",       // type属性是必须要写的，用于校验
        value: gamer_reg.avatar_url,          // value代表要修改为什么值
      }
      const action4 = {
        type: "changeAvatarid",       // type属性是必须要写的，用于校验
        value: gamer_reg.avatar_id,          // value代表要修改为什么值
      }
      const action6 = {
        type: "changeGifid",       // type属性是必须要写的，用于校验
        value: gamer_reg.cheerleader_id,          // value代表要修改为什么值
      }
      if (gamer_reg.cheerleader_gif_url !== null) {
        const action5 = {
          type: "changeGif",       // type属性是必须要写的，用于校验
          value: JSON.parse(gamer_reg.cheerleader_gif_url)[0],          // value代表要修改为什么值
        }
        store.dispatch(action5);
      }
      store.dispatch(action4);

      store.dispatch(action3);
      store.dispatch(action2);
      store.dispatch(action6);



      return
 
    } else {
      setShow(false)
      const action2 = {
        type: "changeCredits",       // type属性是必须要写的，用于校验
        value: gamer_reg.total_credits,          // value代表要修改为什么值
      }
      const action3 = {
        type: "changeAvatar",       // type属性是必须要写的，用于校验
        value: gamer_reg.avatar_url,          // value代表要修改为什么值
      }
      const action4 = {
        type: "changeAvatarid",       // type属性是必须要写的，用于校验
        value: gamer_reg.avatar_id,          // value代表要修改为什么值
      }
      const action6 = {
        type: "changeGifid",       // type属性是必须要写的，用于校验
        value: gamer_reg.cheerleader_id,          // value代表要修改为什么值
      }
      if (gamer_reg.cheerleader_gif_url !== null) {
        const action5 = {
          type: "changeGif",       // type属性是必须要写的，用于校验
          value: JSON.parse(gamer_reg.cheerleader_gif_url)[0],          // value代表要修改为什么值
        }
        store.dispatch(action5);
        // console.log("变更图片");
      }
      store.dispatch(action4);

      store.dispatch(action3);
      store.dispatch(action2);
      store.dispatch(action6);
    }
  }
  function closemodel() {
    console.log("父组件的关闭弹出框");
    setDialog(false)


  }
  function avatarsmodel() {
    console.log("父组件的关闭弹出框");
    let gamer_reg = JSON.parse(localStorage.getItem("gamer_reg"))

    let UUID = gamer_reg.uuid

    fetchstufflist({
      uuid: UUID,
      type: "avatar"
    }).then((res) => {
      console.log(res);
      setAlist(res.data)
    })
    fetchaccountdata({
      page: 1,
      uuid: UUID,
      type: "cheerleader",
      page_size:12
    }).then((res) => {
      console.log(res);
      if (res.code == "2") {
        let obj = []
        setMylist(obj)
      } else {
        let obj = res.data
        setMylist([...obj])
        setMytotal(res.total_num)
        console.log(mylist);
      }

    })
    setAvatarsdialog(false)


  }
  function buyAvatars(e) {
    console.log(e);
    setAvatarsdialogdata(e)
    setAvatarsdialog(true)
  }
  // 分页下一页
  function changepageright() {

    if (mypage * 12 >= mytotal) {
      message.info('已经是最后一页了!')
    } else {
      setLoading(true)
      // setTimeout(() => {
      //   setLoading(false)
      // }, 2*1000); 
// 执行上述代码，两秒后会在控制台输出：我在两秒后被输出！

      setMypage((data) => { let new_data = data + 1; return new_data })
      let gamer_reg = JSON.parse(localStorage.getItem("gamer_reg"))
      let UUID = gamer_reg.uuid
  
      fetchaccountdata({
        uuid: UUID,
        type: "cheerleader",
        page:mypage+1,
        page_size:12
      }).then((res) => {
        setLoading(false)
        let obj = res.data
  
     
        setMylist(mylist.concat(obj))
        console.log(mylist);
  
      })
  
    }



  }
  return (
    <div className='Edit'>
      <div className='bt' style={{ display: show == true ? 'block' : 'none' }}>
        My Game Data
      </div>
      <div className='bt' style={{ display: show == false ? 'block' : 'none' }}>
        Game Overview
      </div>
      <div className='edit-mark' style={{ display: show == true ? 'block' : 'none' }}>
        {/* <div className='edit-header'>
            <div className='left'> <ShareAltOutlined />
        
        <span className='smallfont'>SHARE</span></div>
            <div className='right'>
         
        
        <span className='smallfont-right'>Enter Reading Mode</span>
        <ShareAltOutlined />
                   
  
            
            </div>



       
        </div> */}
        <div className='edit-center'>
          <div className='clcs-top'>
            <img className='clcs-banner' src={require('../assets/img/logo/CLCS.png')} alt="" />
            <div className='clcs-top-right'>
              <p className='li1' style={{ color: "#df8705" }}>
                Cheer Cursor

              </p>
              <p style={{ color: "#4ea1db",marginTop:"18px" }}>"Custom cursor with cheerleader animations for added fun!"</p>
              {/* <Radio.Group options={optionsWithDisabled} onChange={CursoronChange} value={Cursorvalue} optionType="button" buttonStyle="solid" /> */}

              
            </div>
          </div>
          <div className='clcs-center'>
            <Row gutter={22}>

              {mylist.map((item, idx) => (
                // gifId
                // style={{display:item.file_type=="image"&&idx>3?'block':'none'}}

                <Col key={idx} span={6}>
                  <div className='big-box'>
                    <div className='clcs-center-li'>
                      <img src={item.propertycheer.url} alt="" />
                      
                      {/* <div className='clcs-center-li-right'>
                        <img src={require('../assets/img/Cursor1.png')} alt="" />
                        <img src={require('../assets/img/Cursor2.png')} alt="" />
                      </div> */}
                    </div>
                    <div className='clcs-center-li clcs-center-li-bottom'>
               
                    <div className='clcs-center-li-right clcs-center-li-right2' style={{display:item.propertycheer.cursor_had=="y"?'flex':'none'}}>
      
                    {/* <img src={require(`../assets/${item.propertycheer.url_cursor_default?item.propertycheer.url_cursor_default:'images/custom/4/default.png'}`)} alt="" />
                    <img src={require(`../assets/${item.propertycheer.url_cursor_pointer?item.propertycheer.url_cursor_pointer:'images/custom/4/default.png'}`)} alt="" /> */}

                    <img src={item.propertycheer.url_cursor_default} alt="" />
                    <img src={item.propertycheer.url_cursor_pointer} alt="" />
              </div>
                      <div className='clcs-center-li-right clcs-center-li-right2' style={{display:item.propertycheer.cursor_had=="n"?'flex':'none'}}>
                
                        <img src={require('../assets/img/Cursor1.png')} alt="" />
                        <img src={require('../assets/img/Cursor2.png')} alt="" />
                      </div>
                      <div className='clcs-center-li-right'>
          
                      <img src={JSON.parse(item.propertycheer.gifurl)[2]} alt="" />
                      <img  src={JSON.parse(item.propertycheer.gifurl)[1]} alt="" />
                  
                      </div>
                    
                    </div>
                  </div>

                </Col>

              ))
              }

            </Row>
            {/* style={{  display: mypage * 12 >= mytotal ? 'none' : 'flex' }} */}
            <div className='clcs-next'  >
            <Spin className='clcs-next-Spin' spinning={loading}>

      </Spin>
              <div className='clcs-next-button' onClick={changepageright} style={{  display: loading||mypage * 12 >= mytotal ? 'none' : 'flex' }}>
              MORE
                </div>
            </div>

          </div>



        </div>


      </div>

      {/* 这是没有玩过的游戏 */}
      <div className='edit-details' style={{ display: show == false ? 'block' : 'none' }}>
        <div className='details-center'>
          <h4>1. Introduction</h4>

          <p>Custom cursor with cheerleader animations for added fun!

          Cheer Cursor brings a delightful twist to your Chrome browsing experience with AI-generated cute cursor packs and lively cheerleader animations! Unlike other custom cursor extensions, Cheer Cursor pairs your custom cursors with animated cheerleaders performing different dance styles for an extra dose of fun.

          Key Features:
          <h5>1) Dynamic Cheerleader Animations </h5>       
            <p>Your cursor is accompanied by animated cheerleaders who follow its movements and showcase new dance moves based on your actions, such as hovering over a link.</p>
            <h5>2) Random Cursor and Lock Option</h5>    
            <p>Each time you load a page, a random cursor pack is chosen from a collection of 20 unique designs. If you find one you love, you can lock it on the settings page with a single click to toggle the lock on or off.</p>
            <h5>3) Customizable Experience</h5>    
            <ul>
              <li>  - Enable or disable Cheer Cursor features anytime using the toggle switch in the top-right corner of the settings page.</li>
              <li>  - Prefer the custom cursor without animations? Uncheck the "Show Motion" option to keep it simple.</li>
            </ul>
            <p>Transform everyday browsing into an exciting and playful adventure with Cheer Cursor. Say goodbye to dull cursors and hello to fun!</p>
<p>The algorithms of animating cheerleaders refer to the open-source project: </p>

            <a href="https://github.com/facebookresearch/AnimatedDrawings">
            https://github.com/facebookresearch/AnimatedDrawings

            </a>

          </p>
          <hr />
          <h4>2. Installation</h4>
          <p>You can download and install the extension from different browser web stores:


          </p>

          <a href="https://chromewebstore.google.com/detail/cheer-cursor/fpmejpgbamijlhjljoofpcjgkopehfom">
            https://chromewebstore.google.com/detail/cheer-cursor/fpmejpgbamijlhjljoofpcjgkopehfom

          </a>
          <br />


          <hr />


          <h4>3. Screenshots</h4>
          <div className='zmd'>
            <Button
              className="leftButton"
              style={{ left: 55 }}
              onClick={() => {
                carouselEL.current.prev();
              }}
              icon={<LeftOutlined />}
            ></Button>
            <Button
              className="rightButton"
              style={{ right: 55 }}
              onClick={() => {
                carouselEL.current.next();
              }}
              icon={<RightOutlined />}
            ></Button>


            <Carousel
              autoplay

              ref={carouselEL}
            >
              <div>
                <h3 style={contentStyle}>
                  <div className="img">
                    <img src={require('../assets/img/screenshots/scs/img1.png')} alt="" />
                  </div>
                </h3>
              </div>
              <div>
                <h3 style={contentStyle}>
                  <div className="img">
                    <img src={require('../assets/img/screenshots/scs/img2.png')} alt="" />
                  </div>
                </h3>
              </div>
              <div>
                <h3 style={contentStyle}>
                  <div className="img">
                    <img src={require('../assets/img/screenshots/scs/img3.png')} alt="" />
                  </div>
                </h3>
              </div>
              <div>
                <h3 style={contentStyle}>
                  <div className="img">
                    <img src={require('../assets/img/screenshots/scs/img4.png')} alt="" />
                  </div>
                </h3>
              </div>


            </Carousel>
          </div>

        </div>

      </div>
      <Modal
        xdata={dialog}
        setPare={closemodel}
        tourist={tourist}


      ></Modal>
      <AvatarsModal
        xdata={avatarsdialog}
        ydata={avatarsdialogdata}
        setPare={avatarsmodel}
        type="avatar"
      ></AvatarsModal>
    </div>
  );
}

export default Edit;
