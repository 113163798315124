export async function preloadImages(list) {
    let _images = [...list]



    function loadImage() {
        const src = _images.shift()!
        return new Promise((resolve, reject) => {
            const link = document.createElement('link')
            link.rel = 'preload'
            link.as = 'image'
            link.href = src.url
            console.log(src.url);
            
            document.head.appendChild(link)
            link.onload = resolve
            // console.log("这是加载成功了");
            
            link.onerror = reject
            setTimeout(reject, 10000)
        })
    }

    function _loadImage() {
        loadImage().finally(() => {
            if (_images.length>0) {
                _loadImage()
            }else{
                console.log("全部执行完毕");
                
            }
        })
    }
    for (let i = 0; i < 3; i ++) {
        _loadImage()
    }
}